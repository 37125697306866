<template>
	<ViewLayout>
		<template #header-title>
			User Search
		</template>
		<template #header-caption>
			Search for data subjects to view & update their contact or preference details
		</template>
		<template #content>
			<v-row>
				<v-col
					v-if="showCsPortalBrandSelector"
					cols="3"
				>
					<Dropdown
						:value="selectedBrand"
						:label="`${brandFieldLabel}`"
						:items="csPortalBrandSelector"
						custom-sort
						class="align-self-start mb-4 pt-0"
						@input="changeSelectedBrand"
					/>
				</v-col>
				<v-col
					v-if="canSearchForDataSubjectsWithNoPreferences"
					cols="4"
				>
					<v-checkbox
						v-model="searchForDataSubjectsWithNoPreferences"
						label="Include data subjects with no preferences"
						dense
						hide-details
					/>
				</v-col>
			</v-row>
			<SectionCard>
				<template #title>
					Search Criteria
				</template>
				<template #body>
					<validation-observer
						#default="{ handleSubmit }"
					>
						<v-row dense>
							<v-col
								v-for="({ fieldName, fieldLabel }, i) in limitedFields"
								:key="fieldName"
								cols="12"
								sm="3"
							>
								<div
									v-if="i === 7 && limitFields"
									class="d-flex justify-center mt-1"
								>
									<TextButton @click="showMore = true">
										Show more...
									</TextButton>
								</div>
								<TextField
									v-else
									:value="searchParameters[fieldName]"
									:label="fieldLabel || fieldName"
									:rules="{ numeric: fieldName === 'syrenisId' }"
									@input="setValue(fieldName, $event)"
									@keydown.enter="triggerSearch"
								/>
							</v-col>
							<v-col
								v-if="showMore"
								cols="12"
								sm="3"
							>
								<div class="d-flex justify-center mt-1">
									<TextButton @click="showMore = false">
										Show less...
									</TextButton>
								</div>
							</v-col>
						</v-row>
						<div class="d-flex justify-end mt-4">
							<SecondaryActionButton
								class="mr-3"
								@click="clearSearch"
							>
								Clear
							</SecondaryActionButton>
							<PrimaryActionButton
								:disabled="onlyShowDataSubjectsAssociatedWithSelectedBrand && !selectedBrand && selectedBrand !== 0 && showCsPortalBrandSelector"
								@click="handleSubmit(triggerSearch)"
							>
								Search
							</PrimaryActionButton>
						</div>
					</validation-observer>
				</template>
			</SectionCard>
			<SectionCard v-if="searchTriggered">
				<template #title>
					Results
				</template>
				<template #body>
					<DataTable
						:headers="searchResultsHeaders"
						:items="searchResults"
						:item-class="item => item.isBlue ? 'blue lighten-5' : ''"
						@click:row="rowClick"
					/>
				</template>
			</SectionCard>
		</template>
	</ViewLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import ViewLayout from '../../../../../shared/layouts/view-layout.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import DataTable from '../../../../../shared/components/data-table.vue'
import TextButton from '../../../../../shared/components/text-button.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import { getSearchResultsApi } from '../../../../../shared/utils/api/data-subject-search.js'
import { DATA_SUBJECT } from '../../../router/route-names.js'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import {
	dataSubjectSearchFields,
	dataSubjectSearchResults,
	brandFieldLabel,
	onlyShowDataSubjectsAssociatedWithSelectedBrand,
	dataSubjectsWithNoPreferencesTickedByDefault,
	waitForLoad
} from '../../../../../shared/state/configuration.js'
import SectionCard from '../../../../../shared/components/section-card.vue'
import {
	changeSelectedBrand,
	selectedBrand,
	showCsPortalBrandSelector,
	csPortalBrandSelector
} from '../../../../../shared/state/brands.js'
import {
	SEE_DATA_SUBJECTS_WITH_NO_PREFERENCES,
	DATA_SUBJECT_MANAGEMENT_AREA_FULL_PERMISSIONS
} from '../../../../../shared/permissions/cs-portal-permissions.js'

export default {
	components: { SectionCard, TextField, TextButton, DataTable, Dropdown, ViewLayout, PrimaryActionButton, SecondaryActionButton },
	setup () {
		return {
			selectedBrand,
			dataSubjectSearchFields,
			dataSubjectSearchResults,
			showCsPortalBrandSelector,
			brandFieldLabel,
			onlyShowDataSubjectsAssociatedWithSelectedBrand,
			csPortalBrandSelector,
			changeSelectedBrand,
			showSnackbar,
			dataSubjectsWithNoPreferencesTickedByDefault
		}
	},
	data () {
		return {
			searchParameters: {},
			showMore: false,
			searchResults: [],
			searchTriggered: false,
			searchForDataSubjectsWithNoPreferences: false
		}
	},
	computed: {
		...mapGetters('auth', ['hasPermission', 'userHasUniversalBrand']),
		searchResultsHeaders () {
			return this.dataSubjectSearchResults.map(({ fieldName, fieldLabel }) => ({
				text: fieldLabel || fieldName,
				value: fieldName
			}))
		},
		moreThanTwoRows () {
			return this.dataSubjectSearchFields.length > 8
		},
		limitFields () {
			return !this.showMore && this.moreThanTwoRows
		},
		limitedFields () {
			return this.limitFields ? this.dataSubjectSearchFields.slice(0, 8) : this.dataSubjectSearchFields
		},
		canSearchForDataSubjectsWithNoPreferences () {
			return this.hasPermission(SEE_DATA_SUBJECTS_WITH_NO_PREFERENCES) || this.hasPermission(DATA_SUBJECT_MANAGEMENT_AREA_FULL_PERMISSIONS) || this.userHasUniversalBrand
		}
	},
	async created () {
		await waitForLoad()
		if (this.canSearchForDataSubjectsWithNoPreferences) {
			this.searchForDataSubjectsWithNoPreferences = this.dataSubjectsWithNoPreferencesTickedByDefault
		}
	},
	methods: {
		setValue (fieldName, value) {
			this.$set(this.searchParameters, fieldName, value)
		},
		rowClick (row) {
			this.$router.push({
				name: DATA_SUBJECT,
				query: {
					id: row.syrenisId,
					allIds: this.searchResults
						// if group id is null, just bring back singular result of the syrenisId
						.filter(({ groupId, syrenisId }) => groupId !== null ? groupId === row.groupId : syrenisId === row.syrenisId)
						.map(({ syrenisId }) => syrenisId).join(','),
					brandId: this.selectedBrand
				}
			})
		},
		clearSearch () {
			this.searchParameters = {}
			this.searchResults = []
			this.searchTriggered = false
		},
		async triggerSearch () {
			if (this.selectedBrand || this.selectedBrand === 0) {
				const { data: searchResults } = await getSearchResultsApi(this.searchParameters, this.selectedBrand, this.searchForDataSubjectsWithNoPreferences)
				this.searchResults = searchResults || []
				this.searchResults.forEach((result, i) => {
					result.isBlue = i === 0
						? true
						: this.detemineGrouping(i)
				})
				this.searchTriggered = true
			} else {
				showSnackbar({ text: `You must select a ${brandFieldLabel.value} before searching for a data subject.`, color: 'red' })
			}
		},
		detemineGrouping (i) {
			return (this.searchResults[i - 1].groupId !== this.searchResults[i].groupId || [null, undefined, ''].includes(this.searchResults[i].groupId)
				? !this.searchResults[i - 1].isBlue
				: this.searchResults[i - 1].isBlue)
		}
	}
}
</script>
