import { transactionalApi } from '../http-client.js'

export const getSearchResultsApi = (searchParameters, selectedBrand, searchForDataSubjectsWithNoPreferences) => {
	const request = {
		brandId: selectedBrand,
		displayDataSubjectsWithNoPreferences: searchForDataSubjectsWithNoPreferences
	}
	Object.keys(searchParameters).forEach(key => {
		if (searchParameters[key] !== '') request[key] = searchParameters[key]
		if (key === 'syrenisId') request[key] = request[key]?.trim()
	})
	return transactionalApi.post('/api/DataSubjects/Search', request)
}
